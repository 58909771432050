import React from 'react';
import PageHeader from '../../tenant_portal/PageHeader';
import { useLoaderData, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import type {
  LinkAccountQuery as LinkAccountQueryType,
  LinkAccountQuery$data as LinkAccountQueryResponse,
} from './__generated__/LinkAccountQuery.graphql';
import Button from '../Button';
import { OauthProvider } from '../../components/Constants';
import {
  formatProviderName,
  getProviderLogoPath,
} from '../../components/Tools';

export const LinkAccountQuery = graphql`
  query LinkAccountQuery($token: String!) {
    oauthLinkActivation(token: $token) {
      email
      oauthProvider {
        email
        provider
      }
    }
  }
`;

const LinkAccount = () => {
  const { oauthLinkActivationToken } = useParams();

  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    LinkAccountQueryType,
    LinkAccountQueryResponse
  >;

  const [queryReference] = useQueryLoader<LinkAccountQueryType>(
    LinkAccountQuery,
    loaderQueryReference
  );

  const loginActivationData = usePreloadedQuery<LinkAccountQueryType>(
    LinkAccountQuery,
    queryReference as PreloadedQuery<
      LinkAccountQueryType,
      LinkAccountQueryResponse
    >
  );

  const data = loginActivationData.oauthLinkActivation;

  const provider = data?.oauthProvider.provider;

  if (!data || !oauthLinkActivationToken || !provider) {
    return <div>There was an error.</div>;
  }
  const formattedProviderName = formatProviderName(provider);

  const handleLogin = (
    provider: string,
    oauth_link_activation_token: string
  ) => {
    window.location.href = `${RAILS_BASE_URL}/session_transfers/oauth_redirect?provider=${provider}&oauth_link_activation_token=${oauth_link_activation_token}`;
  };

  return (
    <>
      <PageHeader title="Link accounts" />
      <Button
        onClick={() => handleLogin(provider, oauthLinkActivationToken)}
        logoPath={getProviderLogoPath(provider)}
      >
        Continue with {formattedProviderName}
      </Button>
    </>
  );
};

export default LinkAccount;
